.ScoreboardHeader-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ScoreboardHeader-title {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 4px;
  color: rgb(255, 209, 15);
  text-shadow: 1px 2px #000000;
  font-size: 40px;
  text-align: left;
  line-height: 1;
  width: 340px;
  display: inline;
  white-space: nowrap;
}

.ScoreboardHeader-title > h2 {
  margin: 14px 0;
}

.ScoreboardHeader-line-wrapper {
  height: 100%;
  width: 100%;
}

.ScoreboardHeader-line {
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, rgba(240,240,240,1), transparent);
}