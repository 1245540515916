.fadeIn {
  transition: opacity 1s ease;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s ease;
}

.slideIn {
  transition: opacity 1s ease;
}

.slideOut {
  opacity: 0;
  transition: opacity 1s ease;
}