.AllTimeChampions-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3%;
}

.AllTimeChampions-title {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 4px;
  color: rgb(255, 209, 15);
  text-shadow: 1px 2px #000000;
  font-size: 60px;
  text-align: center;
  line-height: 1;
  width: calc(50% - 190px);
  margin-bottom: 140px;
}

.AllTimeChampions-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AllTimeChampions-justify {
  width: calc(50% - 190px);
}