.ScoreboardItem-container {
  padding: 0 20px;
}

.ScoreboardItem-avatar {
  width: 80px;
  height: 80px;
  position: relative;
}

.ScoreboardItem-avatar > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 100%;
}

.ScoreboardItem-avatar > img:first-of-type {
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.ScoreboardItem-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
  background: linear-gradient(to right, rgba(0,146,255,1), transparent);
  height: 60px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.ScoreboardItem-name {
  text-align: center;
  font-size: 26px;
  width: 300px;
  overflow: hidden;
  height: 30px;
  line-height: 1.1;
  text-overflow: ellipsis;
  padding-left:10px;
}

.ScoreboardItem-tickets {
  position: relative;
  right: 0;
}

.ScoreboardItem-tickets-value {
  font-family: 'Bebas Neue', cursive;
  color: rgb(255, 209, 15);
  font-size: 26px;
  line-height: 0.9;
}

.ScoreboardItem-tickets-button {
  border-radius: 20px;
  background-color: rgb(255, 1, 91);
  padding: 1px 20px 2px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoreboardItem-tickets-label {
  font-size: 14px;
}