.Champion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Champion-crown {
  background: url(../images/crown.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 100px;
}

.Champion-avatar {
  margin: 80px 26px 40px 26px;
  width: 180px;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.Champion-container:nth-child(2) .Champion-avatar {
  width: 260px;
  height: 260px;
  margin: 12px 40px;
  position: relative;
}

.Champion-avatar > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.Champion-avatar > img:first-of-type {
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 100%;
}

.Champion-name {
  position: absolute;
  bottom: 10%;
  width: 100%;
  z-index: 100;
  color: #fff;
  font-weight: bold;
  font-size: 0.8em;
}

.Champion-container:nth-child(2) .Champion-name {
  font-size: 1em;
}

.Champion-tickets-value {
  font-family: 'Bebas Neue', cursive;
  color: rgb(255, 209, 15);
  font-size: 40px;
}

.Champion-tickets-button {
  border-radius: 30px;
  background-color: rgb(255, 1, 91);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  padding: 2px 40px 5px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}

.Champion-tickets-label {
  font-size: 22px;
  height: 100%;
}

.gradient {
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100%;
  border-radius: 100%;
  background: linear-gradient(0deg, rgb(0, 28, 70) 0%, rgba(0, 48, 87, 0.764) 15%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
}